import React, { useEffect, useRef } from 'react';
import './Captcha.css'
import ReCAPTCHA from 'react-google-recaptcha-enterprise';

const Captcha: React.FC<{onChange: ((token: string | null) => void), refresh: number}> = ({onChange, refresh}) => {

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    //performRefresh(); //children function of interest
    console.log("Reset captcha");
    recaptchaRef.current?.reset();
  }, [refresh]);

  (window as any).recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  return (
    <div className='captcha-container '>
        <text className='text-medium'>
          {/* @ts-ignore */ }
          <ReCAPTCHA sitekey='6Le2Dn8lAAAAANnhUpDpvu9pb32z049TiaP0mXTH' onChange={onChange} ref={recaptchaRef}/>
        </text>
    </div>
  )
};

export default Captcha