import React from 'react';
import './ErrorMessage.css';
import closeIcon from "./close.png";

interface ErrorMessageProps {
    message: string
    onClose: () => void;
    mode?: 'error' | 'warning'
}

const ErrorMessage = ({message, onClose, mode = 'error'}: ErrorMessageProps) => {
  return (
    <div className='error-message-container' style={{ border: `2px solid var(--${mode})` }}>
        <button className='error-message-close-btn' style={{ color: `var(--${mode})` }} onClick={()=> onClose()}><img className='close-icon' src={closeIcon}/></button>
        <div>
          <text style={{ color: `var(--${mode})` }} >
            {message}
          </text>
        </div>
    </div>
  )
};

export default ErrorMessage