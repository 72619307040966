import React, { useEffect } from 'react';
import './Footer.css';
import heartIcon from './heart.png';
import { useTranslation } from 'react-i18next';

const openCrispChat = (window as any).openCrispChat;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className='footer'>
      <div>
        <a>{t('NEED_HELP')} </a>
        <a className='need-help text-link' onClick={() => {
          openCrispChat()
        }}>{t('CONTACT_US')}</a>
      </div>
      <div>{t('MADE_WITH')} <img className='heart-icon' src={heartIcon} /> {t('BY')} Televeda</div>
    </div>
  )
};

export default Footer