import React from 'react';
import './MessageBubble.css';
import closeIcon from "../errorMessage/close.png";

interface MessageBubbleProps {
    removeBubble: () => void;
    title: string;
    content: string;
}

const MessageBubble = (props: MessageBubbleProps) => {
  return (
    <>
    <div className='message-bubble'>
      <button className='error-message-close-btn' onClick={()=> props.removeBubble()}><img className='close-icon-sm' src={closeIcon}/></button>
      <text className='text-small-bold'>{props.title}</text>
      <text className='text-small'>{props.content}</text>
    </div>
    </>
  )
};

export default MessageBubble