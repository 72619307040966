import axios from "axios";

interface ParticipantData {
    recaptchaToken: string | null;
    telephoneMobile: string;
    email: string;
    fullName: string;
    inviteCode: string | null;
    cType: string | null;
    associationToCommunity: string;
    deviceInfo: string;
    requestURL: string;
    registrationType: string;
    //reference: string;
}

export class SignupAPI {
    private static handleResponseFromAPI(response: any, resolve: any, reject: any) {
        let errorData: unknown = null;

        if (response.response) {
            errorData = response.response.data;
        } else {
            errorData = {
                result: "error",
                generalError: "Televeda server is not responding. Please check your internet connection.",
            };
        }

        const data = response.data || errorData;

        if (data.result !== "ok") {
            reject(data);
        } else {
            resolve(data);
        }
    }

    private static sendRequestToAPI<T>(endpointUrl: any, payload: any): Promise<any> {
        const _self = this;
        return new Promise((resolve, reject) => {
            axios
                .post(endpointUrl, payload)
                .then(function (response) {
                    _self.handleResponseFromAPI(response, resolve, reject);
                })
                .catch(function (response) {
                    _self.handleResponseFromAPI(response, resolve, reject);
                })
                .finally(function () {});
        });
    }

    static checkIfInviteCodeIsValid(inviteCode: string, isLink: boolean): Promise<{communityName: string, communityLogo: string | null, cType: 'community_link' | 'community_typed' | 'user_link' | 'user_typed'}> {
        return this.sendRequestToAPI("/api/signup/check-invite", { inviteCode: inviteCode, isLink: isLink });
    }

    static checkIfPhoneExists(phone: string) {
        return this.sendRequestToAPI("/api/signup/check-phone", { phone: phone });
    }

    static validateAndRegisterParticipant(participantData: ParticipantData) {
        return this.sendRequestToAPI("/api/signup/register-member-auth0", participantData);
    }

    static sendLoginCode(sendCodeData: { email: string; whereToSendTheCode: string }) {
        return this.sendRequestToAPI("/api/signup/send-login-code", sendCodeData);
    }

    static sessionLogin(sessionLoginData: { idToken: string }) {
        return this.sendRequestToAPI("/api/login/session-login", sessionLoginData);
    }

    static totpSessionLogin(sessionLoginData: { idToken: string }) {
        return this.sendRequestToAPI("/2fa/cookie", sessionLoginData);
    }

    static sendRequest(type: string, data: any) {
        return this.sendRequestToAPI("/api/signup/" + type, data);
    }
}
