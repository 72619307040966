import React from 'react';
import './ScrollBox.css';

interface ScrollBoxProps {
    children: string | JSX.Element | JSX.Element[] | React.ReactNode
}

const ScrollBox = (props: ScrollBoxProps) => {
  return (
    <div className='scroll-box'>
        <div className='scroll-box-content'>
        {props.children} 
        </div>
    </div>
  )
};

export default ScrollBox