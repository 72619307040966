import React, { useEffect, useMemo, useState } from "react";
import './InputField.css';
import { RegisterOptions, FieldErrors, UseFormRegister, FieldValues } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { getCountry } from "../../utils/phoneNumberUtils";

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    options?: RegisterOptions<FieldValues, string> | undefined;
    isErrorThrown?: boolean;
    isOptional?: boolean;
    type?: "text" | "textarea" | "phone";
    country?: string;
    disabled?: boolean;
    onPhoneChange?: (phone: string) => void;
}

const InputField = (props: InputFieldProps) => {
    const INVALID_EMAIL_MESSAGE = "invalid email address";

    const [country, setCountry] = useState("");
    const [phoneVal, setPhoneVal] = useState(props.value as string)

    useEffect(() => {
        const country = getCountry();
        country.then((response) => {
            setCountry(response);
        });
    }, []);

    useEffect(() => {
      setPhoneVal(props.value as string);
    }, [country])

    const error = useMemo(()=>{
      if(props.errors[props.name]?.type==='required'){
        return 'Required'
      }
      return props.errors[props.name]?.message as string;
    }, [props.errors[props.name]?.message, props.errors[props.name]])
    
  return (
    <>
      <div className={'input-wrapper'}>
        {(!props.type || props.type==='text') && 
          <input 
            disabled={props.disabled || false}
            className={!!props.errors[props.name] ? 'input-field input-field-required' : 'input-field'} 
            defaultValue={props.defaultValue} 
            {...props.register(props.name, {...props.options})} 
            placeholder={props.placeholder}/
          >
        }
        
        {props.type==='textarea' && 
          <textarea 
            placeholder={props.placeholder} 
            disabled={props.disabled || false}
            className={!!props.errors[props.name] ? 'textarea input-field-required' : 'textarea'} 
            {...props.register(props.name, 
            {...props.options})}
          />
        }

        {props.type==='phone' &&
         <PhoneInput 
          country={country} 
          value={phoneVal}
          disabled={props.disabled || false}
          inputClass={props.value ? 'phone-input-bold' :'phone-input-regular'}
          buttonClass={'phone-button'}
          onChange={(phone) => {
            if(props.onPhoneChange){
              props.onPhoneChange(phone)
            }
          }}
          />
        }
        
        { error && <text className="error-text">{error}</text> }
      </div>
    </>
  )
};

export default InputField;
