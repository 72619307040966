import React, { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import {auth} from '../../FirebaseConfig';
import ConfettiExplosion from 'react-confetti-explosion';
import { deleteCookie, getCookie } from '../../utils/cookie-typescript-utils';
import { useTranslation } from 'react-i18next';

const VerificationCompleteScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user, loading, error] = useAuthState(auth);

  const [redirectCooldown, setRedirectCooldown] = useState(5);

  const [goToParam, setGoToParam] = useState('');

  const redirectUser = () => {
    const currentURL = new URL(window.location.href);
    window.location.href = currentURL.origin + "/" + goToParam;
  }

  useEffect(()=>{
    if(redirectCooldown>0){
      setTimeout(()=>{
        setRedirectCooldown(redirectCooldown-1)
      }, 1000)  
    }
    else {
      redirectUser()
    }
  }, [redirectCooldown])

  useEffect(()=> {
    // if(isSignInWithEmailLink(auth, window.location.href)){
    //   let email = localStorage.getItem('email');
    //   if(email){
    //     signInWithEmailLink(auth, email, window.location.href)
    //     .then((result)=>{
    //       localStorage.removeItem('email');
    //     })
    //   }
    // }
    const goTo = getCookie('goTo');
    if(goTo){
      setGoToParam(goTo);
      deleteCookie("goTo");
    }
  }, [])

  return (
    <div className='body-container'>
        <ConfettiExplosion/>
        <div className='content-container-standalone'>
            <div className='text-container'>
              <text className='text-big'>{t('VERIFICATION_COMPLETE')}</text>
              <text className='text-medium'>{`${t('VERIFICATION_COMPLETE_SCREEN_TEXT_ONE')} ${goToParam ? t('DESTIONATION') : t('CLASS_SCHEDULE')} ${t('IN')} [${redirectCooldown}]`}</text>
            </div>
            <Button title={goToParam ? t('GO_TO_DESTINATION') : t('GO_TO_CLASS_SCHEDULE')} onClick={redirectUser}/> 
        </div>
    </div>
  )
};

export default VerificationCompleteScreen