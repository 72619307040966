import React from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
    numberOfSteps: number;
    currentStep: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div className='progress-bar'>
        {
            Array(props.numberOfSteps).fill(0).map((element, index)=>{
                if(index===props.currentStep-1) return <div key={index} className='progress-bar-step-filled'/>
                else return <div key={index} className='progress-bar-step-empty'/>
            })
        }
    </div>
  )
};

export default ProgressBar