import { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import InputField from '../../components/inputField/InputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { SignupAPI } from '../../logic/signupApi/signupApi';
import { auth } from '../../FirebaseConfig';
import {
  ConfirmationResult, PhoneAuthProvider,
  signInWithCredential,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  setPersistence,
  EmailAuthProvider,
  inMemoryPersistence, getMultiFactorResolver,
  MultiFactorResolver,
  TotpMultiFactorGenerator
} from "firebase/auth";
import ErrorMessage from '../../components/errorMessage/ErrorMessage';
import Message from '../../components/message/Message';
import Loader from '../../components/loader/Loader';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/modal/Modal';

const VerifyCodeScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { register, handleSubmit, watch, formState: { errors } } = useForm()

  console.log("Verification:", useLocation().state);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const email = useLocation().state?.email || null;
  const phone = useLocation().state?.phone || null;
  const phoneConfirmation = useLocation().state?.phoneConfirmation || null;
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2FA, setIsLoading2FA] = useState<boolean>(false);

  const [isVerifyingByPhone, setIsVerifyingByPhone] = useState<boolean>(false);
  const [phoneConfirmationState, setPhoneConfirmationState] = useState<ConfirmationResult>(JSON.parse(phoneConfirmation));

  const handleLoginErrors = (error: any) => {
    setIsLoading(false);
    console.log('error while trying to login with email - google:', error);

    switch (error.code) {
      case "auth/too-many-requests":
        setErrMsgTxt("Too many failed attempts. Please try again later.")
        break;
      case "auth/multi-factor-auth-required":
        setErrMsgTxt("This account has 2fa set. Please use the code from your authentication app!");
        try {
          const mfaResolver = getMultiFactorResolver(auth, error)
          setResolver(mfaResolver);
          setIsModalOpen(true);
        } catch (error) {
          setErrMsgTxt("Firebase internal error. Please try again later!")
          console.log('firebase error', error);
        }
        break;
      default:
        setErrMsgTxt("Wrong verification code.");
    }
    setErrMsgVisible(true);
  }

  const signInWithEmail = (data: any) => {
    setIsLoading(true);

    setPersistence(auth, inMemoryPersistence)
    const credentials = EmailAuthProvider.credential(email, data.code?.trim());

    signInWithCredential(auth, credentials).then(async (result: any) => {
      
      const idToken = await result.user.getIdToken(true);
      return await SignupAPI.sessionLogin({ idToken }).catch((error: unknown) => {
        console.log('error while trying to login with email - server: ', error);
        setIsLoading(false);
      });
    })
    .then(async () => {
        setIsLoading(false);
        return await auth.signOut();
      })
      .then(() => {
        navigate('/login/verification-complete')
      })
      .catch((error: any) => {
        handleLoginErrors(error);
        setIsLoading(false);
      });
  }

  const signInWithPhone = (data: any) => {
    setIsLoading(true);

    const confirmation: ConfirmationResult = phoneConfirmationState;
    const credentials = PhoneAuthProvider.credential(confirmation.verificationId, data.code?.trim());

    signInWithCredential(auth, credentials).then(async (result: any) => {
      const idToken = await result.user?.getIdToken(true);
      return await SignupAPI.sessionLogin({ idToken })
      .catch((error: unknown) => {
        console.log('error while trying to login with phone - server: ', error);
        setIsLoading(false);
      })
    })
    .then(async () => {
        setIsLoading(false);
        return await auth.signOut();
      })
      .then(() => {
        navigate('/login/verification-complete');
      }).catch((error: any) => {
        handleLoginErrors(error);
        setIsLoading(false);
      });
  }

  const onSubmit = (data: any) => {

    if (isVerifyingByPhone) {
      signInWithPhone(data);
    }
    else {
      signInWithEmail(data);
    }
  };

  const submit2FA = async (data: any) => {
    const otpFromAuthenticator = data.totp_code;
    
    if(!resolver) {
      setErrMsgTxt('Failed to resolve multi-factor authentication.');
      return;
    }
    
    const resolverUID = resolver.hints.filter(value => value.factorId === 'totp')[0]?.uid;
    
    if(!resolverUID) {
      setErrMsgTxt('Failed to resolve multi-factor authentication.');
      return;
    }

    setIsLoading2FA(true);

    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForSignIn(
          resolverUID,
          otpFromAuthenticator
      );
      await resolver.resolveSignIn(multiFactorAssertion).then(async (result) => {
        const idToken = await result.user.getIdToken(true);
        await SignupAPI.sessionLogin({ idToken }).catch((error: unknown) => {
          console.log('error while trying to login with email - server: ', error);
          setErrMsgTxt("Something went wrong while trying to set session cookie!");
          setIsLoading2FA(false);
        });

        await SignupAPI.totpSessionLogin({ idToken }).catch((error: unknown) => {
          console.log('error while trying to login with 2fa - server: ', error);
          setErrMsgTxt("Something went wrong while trying to set second-factor cookie!");
          setIsLoading2FA(false);
        });

      })
        .then(async () => {
          setIsLoading2FA(false);
          return await auth.signOut();
        })
        .then(() => {
          navigate('/login/verification-complete');
        })
        .catch((error) => {
          console.log('Error checking code: ', error)
          setErrMsgTxt("Wrong verification code.");
          setIsLoading2FA(false);
        });
  }

  useEffect(() => {

    if (!phone && !email) {
      navigate('/login/');
    }

    if (phone && !email) {
      setIsVerifyingByPhone(true);
    }
  }, [phone, email]);

  useEffect(() => {
    if (isVerifyingByPhone) {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-resend', { 'size': 'invisible' }, auth);
    }
  }, [isVerifyingByPhone])

  const resendEmailCode = () => {
    setIsLoading(true);
    SignupAPI.sendLoginCode({
      whereToSendTheCode: 'email',
      email: email
    })
      .then(() => {
        setIsLoading(false);
        setResendCodeMsgVisible(true);
      })
      .catch((error: unknown) => {
        setIsLoading(false);
        console.log('error while sending login code: ', error)
      });

  };

  const resendPhoneCode = () => {
    setIsLoading(true);
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        setIsLoading(false);
        setResendCodeMsgVisible(true);
        setPhoneConfirmationState(confirmationResult);
      }).catch((error) => {
        setIsLoading(false);
        console.log("SMS Error: ", error);
      });

  }

  const resendCode = () => {
    if (isVerifyingByPhone) {
      resendPhoneCode();
    }
    else {
      resendEmailCode();
    }
  }

  const [errMsgVisible, setErrMsgVisible] = useState(false);
  const [errMsgTxt, setErrMsgTxt] = useState('');

  const [resendCodeMsgVisible, setResendCodeMsgVisible] = useState(false)

  return (
    <div className='body-container'>
      <div className='content-container'>
        {
          isModalOpen &&
          <Modal>
              <div className='modal-close-button-wrapper'>
                <img
                  src={`../televeda/img/public-dashboard/close-icon.svg`}
                  onClick={() => {
                      setIsModalOpen(false)
                  }}   
                />
              </div>
              <form onSubmit={handleSubmit(submit2FA)} className='form-container' >
                <InputField
                  name="totp_code"
                  errors={errors}
                  register={register}
                  options={{
                    required: true,
                    validate: {
                      minLength: (v) => v.length >= 0,
                    }
                  }}
                  placeholder={t('CODE_FROM_APP')}
                />
                {isLoading2FA && <Loader />}
                <Button title={t('FINISH')} />
              </form>
          </Modal>
        }

        {
          errMsgVisible && <ErrorMessage message={errMsgTxt} onClose={() => { setErrMsgVisible(false); }} />
        }
        {resendCodeMsgVisible &&
          <Message onClose={() => setResendCodeMsgVisible(false)}
            title={t('NEW_CODE_SENT')}
            children={
              <>
                {!isVerifyingByPhone && <text>{t('VERIFY_CODE_SCREEN_TEXT_FIRST')}</text>}
              </>
            } />
        }
        <div className='text-container'>
          <text className='text-big'>{t('ALMOST_DONE')}</text>
          &nbsp;
          <text className='text-medium'>{`A 6-digit code has been sent to ${isVerifyingByPhone ? phone : email}`}</text>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='form-container' >
          <InputField
            disabled={isModalOpen}
            name="code"
            errors={errors}
            register={register}
            options={{
              required: true,
              validate: {
                minLength: (v) => v.length >= 0,
              }
            }}
            placeholder={t('ENTER_THE_CODE_HERE')}
          />
          <div className='verification-text'>
            <text id="recaptcha-container-resend" className='text-link'
              onClick={() => {
                resendCode();
              }}>{t('RESEND_CODE')}</text>
            <text className="text-link" onClick={() => navigate(`/login/${isVerifyingByPhone ? 'phone' : ''}`, { state: { filledEmail: email, filledPhone: phone } })}>{t('GO_BACK')}</text>
          </div>
          &nbsp;
          {isLoading && <Loader />}
          <Button disabled={isModalOpen} title={t('CONTINUE')} />
          &nbsp;
          {phone && !isVerifyingByPhone && email &&
            <text
              className='text-link'
              onClick={() => {
                setIsVerifyingByPhone(true);
              }}
            >{t('VERIFY_CODE_SCREEN_TEXT_SECOND')}</text>}
          {isVerifyingByPhone && email &&
            <text
              className='text-link'
              onClick={() => {
                setIsVerifyingByPhone(false);
              }}
            >{t('VERIFY_CODE_SCREEN_TEXT_THIRD')}</text>}
        </form>
      </div>
      <ProgressBar currentStep={2} numberOfSteps={2} />
    </div>
  )
};

export default VerifyCodeScreen