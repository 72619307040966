import React from 'react';
import './Button.css';

interface ButtonProps {
    id?: string;
    title: string;
    onClick?: () => void;
    disabled?: boolean;
}

const Button = ({id = 'default-id', title, onClick, disabled = false}: ButtonProps) => {
  return (
    <button id={id} disabled={disabled} className='button' onClick={onClick}>{title}</button>
  )
};

export default Button