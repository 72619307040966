import React, { useState } from 'react';
import Button from '../../components/button/Button';
import InputField from '../../components/inputField/InputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { useTranslation } from 'react-i18next';

const LandlineScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const { register, handleSubmit, watch, formState: { errors } } = useForm();
  
  // const {email, phone} = useLocation().state;

  return (
    <div className='body-container'>
        <div className='content-container'>
            <div className='text-container'>
                <text className='text-big'>{t('LANDING_SCREEN_TEXT_FIRST')}</text>
                &nbsp;
                <text className='text-medium'>{t('LANDING_SCREEN_TEXT_SECOND')}</text>
                &nbsp;
                <text className='text-link'>+1 (833) 299-1449</text>
            </div>
            
              <Button title={t('CONTINUE')} onClick={()=> navigate("/login/verification")}/>
              &nbsp;
              <text className='text-link'>{t('LANDING_SCREEN_TEXT_THIRD')}</text>
        </div>
        <ProgressBar currentStep={2} numberOfSteps={2}/>
    </div>
  )
};

export default LandlineScreen