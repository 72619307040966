export function getCountry() {
    return fetch("https://ipapi.co/json")
        .then((response: any) => response.json())
        .then((response) => {

            if (!response) {
                throw new Error("unable to fetch the country");
            }

            return response.country_code.toLowerCase();
        });
}